import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

// Tanggal format tgl-bulan-tahun
import moment from 'moment'

// import VueTimepicker from 'vue2-timepicker'
// Vue.use(VueTimepicker)

Vue.filter('date', function(date) {
  return moment(date).format('D MMM Y')
})

import loading from 'vuejs-loading-screen'
import VueCurrencyFilter from 'vue-currency-filter'
import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  globalOptions: { currency: 'IDR' },
}

Vue.use(VueCurrencyInput, pluginOptions)

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

const currencyFilterPluginOptions = [
  {
    name: 'rupiah',
    symbol: 'Rp.',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  {
    name: 'dollar_currency',
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: '',
  },
]

Vue.use(loading, {
  bg: 'rgba(119, 76, 184, 0.8)',
  slot: `
    <div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl text-white"><i class="fas fa-spinner fa-pulse"></i> LOADING...</h3>
    </div>
  `,
})

Vue.use(VueCurrencyFilter, currencyFilterPluginOptions)

Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)

// Tambah request interceptor untuk ongoing request
axios.interceptors.request.use(
  function(config) {
    // Ambil token
    const token = localStorage.getItem('token')

    // Jika access token ada, set authorization header tiap request
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
